<template>
  <div>
    <v-container>
      <s-crud
        :filter="filter"
        :config="config"
        add
        edit
        @EditEvent="EditEvent()"
        @addEvent="itemsDataDetail=[]; ExlID=0"
        remove
        @rowSelected="rowSelected($event)"
        @save="save($event)"
        title="Liquidaciones"
      >
        <template v-slot:filter>
          <v-col>
            <v-row justify="center" style="padding-bottom: 20px">
              <!-- <v-col cols="12" lg="3" sm="3" md="3">
                <s-select-definition
                  v-model="filter.ExlContainer"
                  label="Contenedor"
                ></s-select-definition>
              </v-col> -->
              <v-col cols="12" lg="3" sm="3" md="3">
                <s-date
                  v-model="filter.ExlPackingDate"
                  label="Fecha Embarque"
                ></s-date>
              </v-col>
              <v-col cols="12" lg="3" sm="3" md="3">
                <s-date
                  v-model="filter.ExlLiquidationDate"
                  label="Fecha Liquidación"
                ></s-date>
              </v-col>
            </v-row>
          </v-col>
        </template>

        <template scope="props">
          <v-col style="padding-bottom: 30px">
            <v-row>
              <v-col cols="3" class="s-col-form">
                <s-text
                  number
                  v-model="props.item.ExlPackingWeek"
                  label="semana empaque"
                ></s-text>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <sselect-client-sap
                  v-model="props.item.ExlClientName"
                  label="Cliente"
                ></sselect-client-sap>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-date
                  v-model="props.item.ExlPackingDate"
                  label="Fecha empaque"
                ></s-date>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-text
                  v-model="props.item.ExlLiquidationNumber"
                  label="Número de liquidación"
                ></s-text>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-date
                  v-model="props.item.ExlLiquidationDate"
                  label="Fecha Liquidación"
                ></s-date>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-select-definition :def="1280" v-model="props.item.TypeVia" label="Via">

                </s-select-definition>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-select-definition
                  :def="1172"
                  label="Tipo cultivo"
                  v-model="props.item.TypeCultive"
                />
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-text
                  v-model="props.item.ExlContainer"
                  label="Contenedor"
                ></s-text>
              </v-col>
            </v-row>
          </v-col>
          <v-divider></v-divider>
          <v-col>
            <h4 style="display: inline">Detalles de liquidaciones</h4>
            <v-btn
              dark
              color="teal darken-1"
              @click="addDetail()"
              style="margin-left: 20px"
              inline
              small
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
            </v-btn>
            <v-row style="padding-top: 20px; padding-bottom: 30px">
              <v-col cols="3" class="s-col-form">
                <s-select-definition
                  :def="1274"
                  v-model="itemsDetail.TypePresentation"
                  label="Presentación"
                ></s-select-definition>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-select-definition
                  :def="1266"
                  label="Calibre"
                  v-model="itemsDetail.TypeCaliber"
                />
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-text
                  number
                  v-model="itemsDetail.ExdAmountBoxes"
                  label="Cant. Cajas"
                ></s-text>
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-text v-model="itemsDetail.ExdPrice" decimal label="Precio"></s-text>
              </v-col>
            </v-row>
            <s-toolbar dark color="primary" label="Detalles"></s-toolbar>
            <v-data-table item-key="Line" :headers="headerDetail" :items="itemsDataDetail">
              <template v-slot:item.Actions="{ item }">
                <v-btn
                  color="teal darken-1"
                  dark
                  x-small
                  @click="removeItem(item)"
                  block
                >
                  <v-icon small>mdi-minus-circle-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </template>
      </s-crud>
    </v-container>
  </div>
</template>
<script>
import _sLiquidation from "@/services/ExternalTrade/ExtLiquidationService";
import SselectClientSap from "../../../components/Sale/SselectClientSap.vue";
import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';

export default {
  components: { SselectClientSap, SSelectDefinition },
  data: () => ({
    itemsDetail: {},
    itemsDataDetail: [],
    ExlID : 0,
    dataGetDetail : [],
    Actions: "",
    headerDetail: [
      { text: "Item", value: "Line", width: 60 },
      { text: "Presentación", value: "TypePresentation", width: 60 },
      { text: "Calibre", value: "TypeCaliber", width: 60 },
      { text: "Cant. Cajas", value: "ExdAmountBoxes", width: 60 },
      { text: "Precio", value: "ExdPrice", width: 60 },
      { text: "Actions", value: "Actions", width: 60 },
    ],
    filter: {
      ExlContainer:"",
      ExlPackingDate:"",
      ExlLiquidationDate : ""
    },
    itemsRemoved: [],
    config: {
      model: {
        ExlID: "ID",
        ExlPackingDate :"date",
        ExlLiquidationDate : "date"
      },
      service: _sLiquidation,
      headers: [
        { text: "Sem. Empaque", value: "ExlPackingWeek", width: 90 },
        { text: "Cliente", value: "ExlClientName", width: 90 },
        { text: "F. empaque", value: "ExlPackingDate", width: 90 },
        { text: "N° Liquidación", value: "ExlLiquidationNumber", width: 90 },
        { text: "Fec. Liquidación", value: "ExlLiquidationDate", width: 90 },
        { text: "Via", value: "TypeVia", width: 90 },
        { text: "Tipo cultivo", value: "TypeCultive", width: 90 },
        { text: "Contenedor", value: "ExlContainer", width: 90 },
        { text: "Total cajas", value: "ExlTotalBoxes", width: 90 },
        { text: "Monto Total", value: "ExlTotalAmount", width: 90 },
      ],
      
    },
  }),
  methods: {
    addDetail() {
      console.log("Detalles a guardar", this.itemsDetail);
      this.itemsDetail.ExlID = this.ExlID;
      this.itemsDetail.Line = this.itemsDataDetail.length + 1;
     
      this.itemsDataDetail.push(this.itemsDetail);
      this.itemsDetail = {};
    },

    orderDetails() {
      let i = 0;
      this.itemsDataDetail.map((e) => {
        i++;
        e.Line = i;
      });
    },
    EditEvent(){
      console.log('evento emitido cuando se va a editar')
      this.itemsDataDetail = this.dataGetDetail;
      this.orderDetails()

    },
    save(item) {
      console.log("datos a guardar", item);
      item.UsrCreateID = this.$fun.getUserID();
      item.SecStatus = 1;


      item.ExtLiquidationDetail = [
        ...this.itemsDataDetail, 
        ...this.itemsRemoved,
      ];


      console.log("todos los datos para guardar", item);
      item.save();
      this.itemsDetail = {};
     
    },

    rowSelected(item) {
      console.log('datos de la fila seleccionada', item)

      if(item.length > 0 ){
        this.ExlID = item[0].ExlID
      _sLiquidation.getDetail(this.$fun.getUserID(), this.ExlID)
      .then((r)=>{
        this.dataGetDetail = r.data;
        
        console.log('detalles traidos', this.dataGetDetail)
      });

      }

    },

    removeItem(item) {

      if (this.itemsDataDetail.length > 0) {
					if (item.EldID > 0) {
						this.itemsRemoved.push(
							this.itemsDataDetail.filter(x => x.Line == item.Line)[0],
						);

             console.log('por eliminar', this.itemsRemoved)


						this.itemsRemoved.forEach(element => {
							element.SecStatus = 0;
						});
					}

					this.itemsDataDetail = this.itemsDataDetail.filter(
						x => x.Line != item.Line,
					);
					this.orderDetails();

         
				}
     
      this.orderDetails();
    },
  },
};
</script>