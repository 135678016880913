import Service from '../Service';

const resource = "liquidation/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID }
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    getDetail (requestID,ExlID) {
        return Service.post(resource + "getDetail", ExlID,{
            params: {requestID:requestID, ExlID:ExlID},
        });
    },

}